import appBranding from '@happylife-a/branding';
import enums from '@happylife-a/enums';
import s3Storage from '@happylife-a/s3-storage';
import utils from '@happylife-a/utils';
import * as auth from '../../../auth';

import { getCurrentLocaleFromStorage } from '../../../contexts/TranslationContext';
import * as helpers from '../../../helpers';
import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   UserService: ReturnType<import('../../services/UserService').default>,
 *   CognitoAuthService: ReturnType<import('../../services/CognitoAuthService').default>,
 *   AwsS3Service: ReturnType<import('../../services/AwsS3Service').default>,
 * }} param0
 */
export default function userUseCase({
  UserService,
  CognitoAuthService,
  AwsS3Service,
}) {
  const updateTokens = async () => {
    const refreshToken = await auth.helpers.getRefreshToken();
    if (!refreshToken) {
      return null;
    }

    const identifier = await auth.helpers.getIdentifier();
    const response = await CognitoAuthService.updateTokens({
      identifier: identifier,
      refreshToken: refreshToken,
    });

    await auth.helpers.processTokenResponse(identifier, response);

    if (response) {
      return response.expireAt;
    }

    return null;
  };

  const getSignedInUser = auth.apiWrapper.withReAuthentication(
    async (params) => {
      const success = await auth.helpers.initialize();
      if (!success) {
        if (!params.allowGuestUser) {
          return null;
        }

        // register new guest user
        const locale = await getCurrentLocaleFromStorage();
        const response = await UserService.registerGuestUser({
          locale: locale,
        });

        await auth.helpers.setLoggedUser(response.user, response.accessToken);
      }

      const me = await UserService.getMe();
      return me;
    },
    async (e) => {
      utils.helpers.logging.error('Get signed-in user error');
      utils.helpers.logging.exception(e);

      await auth.helpers.clearTokenStorage();
      return null;
    },
    updateTokens
  );

  const getMe = auth.apiWrapper.withReAuthentication(
    async () => {
      const success = await auth.helpers.initialize();
      if (success) {
        const user = await UserService.getMe();
        return user;
      }

      throw new Error('No access token found in storage to fetch user.');
    },
    async (e) => {
      utils.helpers.logging.error('Get me error');
      utils.helpers.logging.exception(e);

      await auth.helpers.clearTokenStorage();
      return null;
    },
    updateTokens
  );

  const updateMe = (variables) => UserService.updateMe(variables);

  const signIn = async (
    { identifier, password, role },
    { isGuestUser, guestUserId }
  ) => {
    if (
      role === enums.user.UserRoleEnum.CUSTOMER &&
      appBranding.isFeatureEnabled('legalCustomer') &&
      !utils.helpers.email.isValidEmail(identifier)
    ) {
      identifier =
        'company_' +
        auth.helpers.sanitizeIdentifier(identifier, role)?.replace(/\s/g, '_');
    } else {
      identifier = auth.helpers.sanitizeIdentifier(identifier, role);
    }

    const response = await UserService.checkUserIdentity({
      role: role,
      identifier: identifier,
    });

    if (!response || !response.user) {
      return null;
    }

    const signInResponse = await CognitoAuthService.signIn({
      identifier: response.user.identifier,
      password: password,
    });

    await auth.helpers.processTokenResponse(identifier, signInResponse);

    if (isGuestUser && guestUserId) {
      await UserService.syncGuestUser({ guestUserId: guestUserId });
    }

    return UserService.getMe();
  };

  const signUp = async (variables) => {
    variables.role = variables.role || enums.user.UserRoleEnum.CUSTOMER;
    if (!variables.nickname && variables.shopName) {
      variables.nickname =
        'shop_' +
        auth.helpers
          .sanitizeIdentifier(variables.shopName, variables.role)
          ?.replace(/\s/g, '_');
    }

    if (
      !variables.nickname &&
      variables.companyName &&
      variables.role === enums.user.UserRoleEnum.CUSTOMER &&
      variables.activityType &&
      variables.activityType === enums.user.CustomerTypeEnum.LEGAL
    ) {
      variables.nickname =
        'company_' +
        auth.helpers
          .sanitizeIdentifier(variables.companyName, variables.role)
          ?.replace(/\s/g, '_');
    }

    if (variables.phoneNumber && !variables.phoneNumber.startsWith('+')) {
      variables.phoneNumber = utils.helpers.phone.toValidPhoneNumber(
        variables.phoneNumber,
        appBranding.configs.countryParams.phoneCode
      );
    }

    variables.address = variables.address || variables.pickupAddress;
    variables.email = auth.helpers.sanitizeIdentifier(
      variables.email,
      variables.role
    );
    variables.nickname = auth.helpers.sanitizeIdentifier(
      variables.nickname,
      variables.role
    );
    variables.phoneCode = variables.phoneCode || null;

    const userInput = {
      // NOTICE: keep only fields which you will receive
      phone: variables.phoneNumber,
      phoneCode: variables.phoneCode,
      nickname: variables.nickname,
      email: variables.email,
      firstName: variables.name,
      lastName: variables.surname,
      locale: variables.locale,
    };

    const setUserFieldValueOrDefault = (field, defaultValue = null) => {
      const value = variables[field] || defaultValue;
      if (value) {
        userInput[field] = value;
      }
    };

    setUserFieldValueOrDefault('activityType');
    setUserFieldValueOrDefault('gender');
    setUserFieldValueOrDefault('role', enums.user.UserRoleEnum.CUSTOMER);
    setUserFieldValueOrDefault('birthday');
    setUserFieldValueOrDefault('bankName');
    setUserFieldValueOrDefault('bankAccountNumber');
    setUserFieldValueOrDefault('address');
    setUserFieldValueOrDefault('shopName');
    setUserFieldValueOrDefault('bankAccountNumber');
    setUserFieldValueOrDefault('sellerRole');
    setUserFieldValueOrDefault('companyName');
    setUserFieldValueOrDefault('accountType');
    setUserFieldValueOrDefault('country');
    setUserFieldValueOrDefault('companyType');
    setUserFieldValueOrDefault('physicalName');
    setUserFieldValueOrDefault('physicalSurname');
    setUserFieldValueOrDefault('taxCode');
    setUserFieldValueOrDefault('documentKey');
    setUserFieldValueOrDefault('sellerAddress');
    setUserFieldValueOrDefault('locale');

    const responseCheck = await UserService.createUser({
      ...userInput,
      validateOnly: true,
    });

    if (responseCheck) {
      const identifier =
        responseCheck.identifyVia === 'nickname'
          ? variables.nickname
          : variables.email;

      const responseCognito = await CognitoAuthService.signUp({
        identifier: identifier,
        password: variables.password,
        attributes: {
          given_name: variables.name || 'First',
          family_name: variables.surname || 'Last',
          email: variables.email,
        },
      });

      const responseCreate = await UserService.createUser({
        ...userInput,
        validateOnly: false,
        cognitoUserId: responseCognito.userId,
      });

      return {
        ...responseCreate.user,
        identifier: identifier,
      };
    }
  };

  const confirmSignUp = async (variables) => {
    variables.role = variables.role || enums.user.UserRoleEnum.CUSTOMER;
    variables.identifier = auth.helpers.sanitizeIdentifier(
      variables.identifier,
      variables.role
    );

    const response = await CognitoAuthService.confirmSignUp(variables);
    return response;
  };

  const forgotPassword = async (variables) => {
    variables.identifier = auth.helpers.sanitizeIdentifier(
      variables.identifier
    );
    return CognitoAuthService.forgotPassword(variables);
  };

  const resetPassword = async (variables) => {
    variables.identifier = auth.helpers.sanitizeIdentifier(
      variables.identifier
    );
    return CognitoAuthService.resetPassword(variables);
  };

  const changePassword = async (variables) =>
    CognitoAuthService.changePassword(variables);

  const resendConfirmSignUp = async (variables) =>
    CognitoAuthService.resendConfirmSignUp(variables);

  const signOut = async () => {
    const refreshToken = await auth.helpers.getRefreshToken();
    const result = await CognitoAuthService.signOut({
      refreshToken: refreshToken,
    });

    await auth.helpers.clearTokenStorage();
    return result;
  };

  const searchCustomers = (search) => UserService.searchCustomers(search);

  const userByEmail = (email) => UserService.userByEmail(email);

  const searchUsers = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return UserService.searchUsers(serviceVariables);
  };

  const uploadImage = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_PROFILE_AVATAR;
    const { binary, extension } = await helpers.buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  const isCanRetryWithTokenUpdate = (url) =>
    CognitoAuthService.isCanRetryWithTokenUpdate(url);

  const isLoggedIn = async () => {
    const isLoggedInResult = await auth.helpers.checkIsLoggedIn();
    return isLoggedInResult;
  };

  const getCustomerOrderItems = (idOrEmail, variables) =>
    UserService.getCustomerOrderItems(idOrEmail, variables);

  const deleteAccount = async (input) => {
    const response = await UserService.deleteAccount(input);
    await auth.helpers.clearTokenStorage();

    return response;
  };

  return {
    searchCustomers: searchCustomers,
    signIn: signIn,
    signUp: signUp,
    userByEmail: userByEmail,
    searchUsers: searchUsers,
    getSignedInUser: getSignedInUser,
    confirmSignUp: confirmSignUp,
    signOut: signOut,
    resetPassword: resetPassword,
    forgotPassword: forgotPassword,
    resendConfirmSignUp: resendConfirmSignUp,
    getMe: getMe,
    uploadImage: uploadImage,
    updateMe: updateMe,
    changePassword: changePassword,
    isCanRetryWithTokenUpdate: isCanRetryWithTokenUpdate,
    isLoggedIn: isLoggedIn,
    updateTokens: updateTokens,
    getCustomerOrderItems: getCustomerOrderItems,
    deleteAccount: deleteAccount,
  };
}
