import enums from '@happylife-a/enums';
import appBranding from '@happylife-a/branding';

export function shouldShowPaymentMethods() {
  const paymentProviders = appBranding.configs.payment.providers;
  return (
    paymentProviders.length !== 1 ||
    paymentProviders[0] !== enums.payment.PaymentProviderEnum.Invoice
  );
}
